import React,{ useState } from 'react';
import {Box,Stack,Link, Divider} from '@mui/material'
import { useSearchParams,useLocation } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';


function Header() {

    const baseUrl = process.env.PUBLIC_URL

    const [searchParams] = useSearchParams();
    const hashValue = useLocation().hash;
    

    let queryString = searchParams.toString()
    if(queryString?.length > 0){
        queryString = `?${queryString}`;
    }
    

    const style_menu={
        textDecoration:"none",
        fontWeight:"bold",
        color:"#393939"
    }

    const active_menu={
        color:"#4771DF",
        "&::before":{
            width:"100%"
        }
    }

    const style_menu2={
        textDecoration:"none",
        fontWeight:"bold",
        color:"#393939",
        width:"100%",
        padding:"20px 20px",
        
        
    }


    const [isOpen,setIsOpen] = useState(false);

    const createLink=(title,hash)=>{
        return(
            <Link sx={style_menu} href={baseUrl+`/#${hash}`+queryString}>
                <Box component="span" sx={hashValue===`#${hash}` ? active_menu:{}}>{title}</Box>
            </Link>
        )
    }

    const createMenuLink=(title,hash)=>{
        return(
            <Link sx={style_menu2} href={baseUrl+`/#${hash}`+queryString} onClick={()=>{setIsOpen(!isOpen)}}>
                <Stack flexDirection="row" >
                    <Box flex={1} sx={hashValue===`#${hash}` ? active_menu:{}}>{title}</Box>
                    <ArrowForwardIcon/>
                </Stack>
            </Link>
        )
    }

    return (
        <Box component="header" position="fixed" left={0} right={0} top={0} backgroundColor="rgba(255, 255, 255, 0.9)" zIndex={999} boxShadow="1px 1px 2px rgba(0,0,0,0.3)">
            <Stack className='section-content' flexDirection="row" margin="0 auto" justifyContent="space-between" alignItems="center" gap={1} p={1}>
                <Link sx={style_menu} href={baseUrl+"/"+queryString}>
                    <Box component="img" src={baseUrl+"/static/media/Logo.png"} alt="logo" width={170} mr={5}></Box>
                </Link>
                
                
                <Stack component="nav" fontSize={14} display={{xs:"none",md:"flex"}} flex={1} flexDirection="row" gap={2} justifyContent="end" >
                    {createLink("TOP","top")}
                    
                    {createLink("よくあるお悩み","worries")}
                    
                    {createLink("特徴","featuers")}
                    
                    {createLink("メリット","merit")}
                    
                    {createLink("他社サービスとの違い","difference")}
                    
                    {createLink("依頼できる内容","scope")}
                    
                    {createLink("対応の流れ","serviceFlow")}
                    
                    {createLink("ご利用までの流れ","startFlow")}
                    
                    {createLink("運営会社","company")}
                </Stack>

                <Box display={{xs:"flex",md:"none"}} onClick={()=>{setIsOpen(!isOpen)}}>
                    {isOpen===false ? <MenuIcon/>:<CloseIcon/>}
                </Box>
            </Stack>
            {isOpen &&
                <Stack flexDirection="column" pt="70px" pb="100px" position="fixed" height="100vh" overflow="auto" zIndex={-1} top={0} bottom={0} left={0} right={0} backgroundColor="white" alignItems="center" >
                    <Divider flexItem/>
                    {createMenuLink("TOP","top")}
                    <Divider flexItem/>
                    {createMenuLink("よくあるお悩み","worries")}
                    <Divider flexItem/>
                    {createMenuLink("特徴","featuers")}
                    <Divider flexItem/>
                    {createMenuLink("メリット","merit")}
                    <Divider flexItem/>
                    {createMenuLink("他社サービスとの違い","difference")}
                    <Divider flexItem/>
                    {createMenuLink("依頼できる内容","scope")}
                    <Divider flexItem/>
                    {createMenuLink("対応の流れ","serviceFlow")}
                    <Divider flexItem/>
                    {createMenuLink("ご利用までの流れ","startFlow")}
                    <Divider flexItem/>
                    {createMenuLink("運営会社","company")}
                </Stack>
            }
            
        </Box>
        
    );
}

export default Header;