import React from 'react';
import {Link} from '@mui/material'
import { useSearchParams } from "react-router-dom";


function ConversionButton(props) {

    
    const [searchParams] = useSearchParams();
    
    const baseUrl = "https://triport.co.jp/form/bpo/payroll"

    let partnerId = searchParams.get("id");
    let campainId = searchParams.get("ca");

    if(partnerId == null){
        partnerId = "8f70b68dfe86450fad06adf41c8f4fb2"
    }

    let targetUrl = `${baseUrl}?p=${partnerId}`;

    if(campainId!=null){
        targetUrl += `&ca=${campainId}`
    }
    
    return (
        <Link 
            display={props.display}
            textAlign={props.textAlign}
            position={props.position}
            mt={props.mt}
            color="#FFFFFF" 
            borderRadius={props.borderRadius}
            fontSize={props.fontSize} 
            backgroundColor="#fd615c" 
            border="solid 3px #fd615c" 
            boxShadow="rgba(0, 0, 0, 0.5) 2px 2px 8px 0px"
            p={props.p} 
            fontWeight="bold" 
            href={targetUrl} 
            target={props.target}
            sx={{
                textDecoration:"none",
                "&:hover":{
                    // color:"#fd615c",
                    // backgroundColor:"white",
                    transform:"scale(0.95,0.95)",
                    transition:"transform 0.1s ease",
                    
                    
                },
            }}>{props.children}</Link>
    );
}

ConversionButton.defaultProps = {
    display:"block",
    textAlign:"center",
    target:"_blank",
    
    p:{xs:"20px 10px",md:"20px 20px"},
    borderRadius:50
}

export default ConversionButton;