import './App.css';
import { BrowserRouter,Routes,Route } from "react-router-dom";
import Header from './Pages/Lp/Components/Header';
import Footer from './Pages/Lp/Components/Footer';
import FirstView from './Pages/Lp/FirstView';
import Worries from './Pages/Lp/Worries';
import Features from './Pages/Lp/Features';
import Merit from './Pages/Lp/Merit';
import Difference from './Pages/Lp/Difference';
import Scope from './Pages/Lp/Scope';
import ServiceFlow from './Pages/Lp/ServiceFlow';
import StartFlow from './Pages/Lp/StartFlow';
import Company from './Pages/Lp/Company';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path={"/*"} element={
            <>
              <Header/>
              <FirstView/>
              <Worries/>
              <Features/>
              <Merit/>
              <Difference/>
              <Scope/>
              <ServiceFlow/>
              <StartFlow/>
              <Company/>
              <Footer/>
            </>
          }/>
        </Routes>

      </BrowserRouter>
    </div>
  );
}

export default App;
